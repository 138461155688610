<template>
  <v-app>

    <!-- <v-app-bar app color="white" height="100"> -->
    <v-app-bar app color="white" :height="headerHeight">
      <v-toolbar-title>
        <!-- <v-img contain height="95" width="202" :src="require('./assets/kapsela.png')"></v-img> -->
        <!-- <v-img class="mt-5 mb-2" contain height="90" width="202" :src="require('./assets/kapsela.png')"></v-img> -->
        <v-img class="mt-5 mb-2" contain :height="logoHeight" :width="logoWidth" :src="require('./assets/kapsela.png')"></v-img>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <section id="hero">
        <v-row no-gutters>
          <v-img contain  :src="require('./assets/banner.jpg')">
          </v-img>
        </v-row>
      </section>

      <section id="about-me">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 id="h2about" class="display-2 font-weight-medium mb-3">ABOUT US</h2>

          <v-responsive class="mx-auto mb-8" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
            <!-- Vuetify is the #1 component library for Vue.js and has been in active development since 2016. The goal of the project is to provide users with everything that is needed to build rich and engaging web applications using the Material Design specification. It accomplishes that with a consistent update cycle, Long-term Support (LTS) for previous versions, responsive community engagement, a vast ecosystem of resources and a dedication to quality components. -->
            Kapsela is the flexible suite of software solutions taking productivity to new heights. It automates and optimizes. It capitalized on untapped efficiencies through client-centered, personalized development. Kapsela is industry-leading technologies bolstering success in management practices.
          </v-responsive>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="features" class="grey lighten-3">
        <div class="py-12"></div>

        <v-container class="text-center">
          <h2 id="h2features" class="display-2 font-weight-medium mb-3">KAPSELA FEATURES</h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-row>
            <v-col v-for="({ icon, title, text }, i) in features" :key="i" cols="12" md="4">
              <v-card class="py-12 px-4" color="grey lighten-5" flat>
                <v-theme-provider dark>
                  <div>
                    <v-avatar color="#5B4370" size="88">
                      <v-icon large v-text="icon"></v-icon>
                    </v-avatar>
                  </div>
                </v-theme-provider>
                
                <!-- <v-card-title class="justify-center font-weight-black text-uppercase featureCardTitle" v-text="title"></v-card-title> -->
                <v-card-title class="justify-center font-weight-black text-uppercase featureCardTitle">{{title}}</v-card-title>

                <v-card-text class="subtitle-1" v-text="text">
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <div class="py-12"></div>
      </section>

      <section id="stats">
        <!-- <v-parallax  :height="$vuetify.breakpoint.smAndDown ? 700 : 500" :src="require('./assets/tenant2.jpg')"> -->
        <v-parallax :src="require('@/assets/tenant.jpg')">
          <v-container>
            <v-row d-flex justify="center">
              <v-col cols="12" xl="8" lg="8" md="8" sm="9" xs="9">
                <v-text-field
                  v-model.trim="tenant"
                  light 
                  flat 
                  color="#5FC5F0"
                  placeholder="TENANT / CLIENT / CUSTOMER"
                  rounded
                  filled
                  outlined
                  background-color="rgba(70, 70, 70, 0.7)"
                  class="my-text-style"
                >                 
                </v-text-field>
              </v-col>
              <v-col cols="12" xl="2" lg="2" md="2" sm="12" xs="12" class="d-flex justify-center">
                <v-btn
                  ref="goButton"
                  @click="goToTenantWebPage"
                  x-large
                  rounded
                  color="#5FC5F0"
                >
                GO
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-parallax>
      </section>

      <v-sheet id="contact" color="#5B4779" dark tag="section" tile>
        <div class="py-12"></div>

        <v-container>
          <h2 class="display-2 font-weight-medium mb-3 text-uppercase text-center">Contact Us</h2>

          <v-responsive class="mx-auto mb-12" width="56">
            <v-divider class="mb-1"></v-divider>
            <v-divider></v-divider>
          </v-responsive>

          <v-theme-provider light>
            <v-row>
              <v-col cols="12">
                <v-text-field flat label="Name*" solo></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field  label="Email*" solo></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field flat label="Subject*" solo></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea flat label="Message*" solo></v-textarea>
              </v-col>

              <v-col class="mx-auto" cols="auto">
                <v-btn color="#5FC5F0" x-large>
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-theme-provider>
        </v-container>

        <div class="py-12"></div>
      </v-sheet>
    </v-main>

    <v-footer class="justify-center" color="#5B4370" height="100">
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — kapsela.com
      </div>
    </v-footer>

  </v-app>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
        tenant: '',
        features: [
          {
            icon: 'mdi-shield-outline',
            title: 'SECURITY FIRST',
            text: "Banking-level privacy, from specialists in securing financial transactions. Leave data protection to us. We'll worry so you don’t have to.",
          },
          {
            icon: 'mdi-finance',
            title: `SMALL CHANGES,\n BIG DIFFERENCE`,
            text: 'Small, not insignificant. Tailored, not boilerplate. Continuous improvement strategies from a development team for whom no waste is trivial.',
          },
          {
            icon: 'mdi-handshake',
            title: 'BEYOND SUPPORT',
            text: 'Impactful, feature-rich services born of collaboration and problem solving, not just support. Meet your new partners. Join Kapsela.',
          },
        ],
      }
  },
  computed: {
    headerHeight() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '80px'
          case 'sm': return '100px'
          case 'md': return '100px'
          case 'lg': return '100px'
          case 'xl': return '100px'
          default: return '100px'
        }
    },
    logoHeight() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '70px'
          case 'sm': return '90px'
          case 'md': return '90px'
          case 'lg': return '90px'
          case 'xl': return '90px'
          default: return '90px'
        }
    },
    logoWidth() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return '157px'
          case 'sm': return '202px'
          case 'md': return '202px'
          case 'lg': return '202px'
          case 'xl': return '202px'
          default: return '202px'
        }
    }
  },
  methods: {
    goToTenantWebPage() {
      if(this.tenant === 'INOVA'){
        window.open('https://www.kapsela.com/inova', '_self');
      }
    }
  },
  watch: {
    tenant(curVal, oldVal) {
      const tenantToSearch = 'INOVA'; 
      if(curVal.length >= 3 && oldVal.length < curVal.length && tenantToSearch.indexOf(this.tenant.toUpperCase()) !== -1){
        this.tenant = tenantToSearch;
        this.$refs.goButton.$el.focus();
      }
    }
  }
};
</script>

<style scoped>
#h2features {
  color: #5B4394;
}
#h2about {
  color: #5B4394;
}
.featureCardTitle {
  color: #5B4394;
  /* overflow-wrap: break-word;
  overflow: hidden; */
  word-break: break-word;
}

.my-text-style >>> .v-text-field__slot input {
    color: #5FC5F0;
    font-size: larger;
    font-weight: bold;
}

.my-text-style >>> .v-text-field__slot input::placeholder {
    color: #5FC5F0 !important;
    font-size: medium;
    font-weight: normal;
}

/deep/ .v-toolbar__content {
  padding: 4px 8px !important;
}

</style>
